<template>
  <div class="padding-top-10 edit-contain">
    <top :bgWhite="true"></top>

    <div class="contents padding-bottom-20">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          :to="{ path: '/center_detail', query: { event_aid: event_aid } }"
          >赛事中心</el-breadcrumb-item
        >
        <el-breadcrumb-item>作品上传</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="bg-white margin-top-20 padding-lr-50">
        <div
          class="padding-top-20 fs24 solid-bottom padding-bottom-15 margin-bottom-20"
        >
          文章填写
        </div>
        <el-input
          type="text"
          @keyup.enter="login"
          class="input w100 padding-tb-10 radius-2"
          v-model="form.title"
          placeholder="请输入标题"
        />
        <div class="padding-top-20 fs24 padding-bottom-15">上传内容</div>
        <el-upload
          ref="quillUploader"
          class="avatar-uploader"
          :action="BaseUrl + '/api/v1/5fc615f7da92b'"
          :show-file-list="false"
          :on-success="onSuccessQuill"
          :limit="20"
          :multiple="true"
        >
        </el-upload>
        <div class="">
          <vue-ueditor-wrap
            editor-id="xiumi-demo-01"
            @before-init="addXiumiDialog"
            v-model="form.body"
            :config="myConfig"
          ></vue-ueditor-wrap>
        </div>
        <!-- <div class="  margin-top-60 fs24  padding-bottom-15">
					上传封面
				</div>
				<el-upload class="avatar-uploader" action="http://xiangcunrc.magic-house.cn/api/v1/5fc615f7da92b"
					:show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
					<img v-if="form.imageUrl" :src="form.imageUrl" class="width156 height126">
					<img v-else src="../../assets/image/center/add_img.png" class="width156 height126">
				</el-upload> -->
        <div class="padding-bottom-30 solid-bottom">
          <div class="margin-top-60 fs24 padding-bottom-15">文章分类</div>
          <el-select v-model="form.type" border placeholder="请选择文章分类">
            <el-option
              :label="item.title"
              :value="item.aid"
              v-for="(item, index) in aTypeLists"
              :key="index"
            ></el-option>
          </el-select>
        </div>
        <div class="padding-bottom-30 solid-bottom">
          <div class="margin-top-60 fs24 padding-bottom-15">类项设置</div>
          <el-form ref="form" :model="form">
            <el-form-item label="是否原创">
              <el-radio-group v-model="form.is_original">
                <el-radio :label="1">原创</el-radio>
                <!-- <el-radio :label="0">非原创</el-radio> -->
              </el-radio-group>
            </el-form-item>
            <!-- <el-form-item label="请选择作品类型">
							<el-select v-model="form.artileType" placeholder="请选择作品类型">
								<el-option label="散文" value="shanghai"></el-option>
								<el-option label="类型2" value="beijing"></el-option>
							</el-select>
						</el-form-item> -->
            <el-form-item label="是否首发" v-if="form && form.is_original == 1">
              <el-radio-group v-model="form.is_first_publish">
                <el-radio :label="1">首发</el-radio>
                <!-- <el-radio :label="0">非首发</el-radio> -->
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <!-- <div class=" padding-bottom-30 " v-if="form && form.is_original==1 && form.is_first_publish==1">
					<div class="  margin-top-30 fs24  padding-bottom-15">
						付费设置
					</div>
					<el-form ref="form" :model="form">
						<el-form-item label="">
							<el-radio-group v-model="form.is_free">
								<el-radio :label="0">付费</el-radio>
								<el-radio :label="1">免费</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-form>
					<div class=" padding-lr-20 padding-tb-8 flex justify-between align-center" v-if="form.is_free==0">
						<span>￥</span>
						<el-input type="number" min="0" name="" id="" v-model='form.money' placeholder="请输入阅读费用" />
					</div>
				</div> -->
      </div>

      <el-button
        class="margin-top-70 margin-bottom-20 flex align-center justify-center"
        @click="submitForm()"
        v-preventReClick
      >
        <span
          class="bg-e54f no-border radius-100 padding-lr-60 padding-tb-10 point text-white"
          >发布</span
        >
      </el-button>
    </div>
    <bottom :bgWhite="true"></bottom>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import top from "@/components/top";
import bottom from "@/components/bottom";
import * as Quill from "quill"; // 引入编辑器
import ImageResize from "quill-image-resize-module";
import VueUeditorWrap from "vue-ueditor-wrap";
Quill.register("modules/imageResize", ImageResize);
export default {
  name: "editArticle",
  components: {
    bottom,
    top,
    quillEditor,
    VueUeditorWrap,
  },
  data() {
    return {
      aTypeLists: [], //文章分类列表
      content: "", //富文本内容
      BaseUrl: this.imgUrl,
      myConfig: {
        // 设置编辑器不自动被内容撑高
        autoHeightEnabled: true,
        // 初始容器高度
        initialFrameHeight: 400,
        // 初始容器宽度
        initialFrameWidth: "1000",
        // 可以放后台存放路径
        // serverUrl: "https://www.xcrck.com/admin.php/admin/upload/get_ueditor",
        // UEditor 是文件的存放路径，
        UEDITOR_HOME_URL: "/UEditor/",
        catchRemoteImageEnable: true, // 抓取远程图片
      },
      editorOption: {
        placeholder: "请输入正文",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              [
                {
                  color: [],
                },
                {
                  background: [],
                },
              ],
              ["link", "image"],
              ["blockquote", "code-block"],
              [
                {
                  align: [],
                },
              ],
              [
                {
                  list: "ordered",
                },
                {
                  list: "bullet",
                },
              ],
              [
                {
                  script: "sub",
                },
                {
                  script: "super",
                },
              ],
              [
                {
                  header: 1,
                },
                {
                  header: 2,
                },
              ],
              [
                {
                  header: [1, 2, 3, 4, 5, 6, false],
                },
              ],
              [
                {
                  size: ["small", false, "large", "huge"],
                },
              ],
              [
                {
                  font: [],
                },
              ],
              [
                {
                  indent: "-1",
                },
                {
                  indent: "+1",
                },
              ],
            ],
            handlers: {
              image: function (value) {
                if (value) {
                  // 触发element-ui的文件上传
                  document.querySelector(".avatar-uploader input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
        },
      },
      imageUrl: "", //封面
      form: {
        title: "", //标题
        body: "", //文章内容
        is_original: 1,
        // imageUrl: '', //封面
        // artileType: '', //文章类型
        is_first_publish: 1, //是否首发
        is_free: 1, //是否免费
        money: "",
        ducoment_type: 2, //类型  1动态 2文章 3电子书
        type: "", //文章分类id
      },
      event_aid: this.$route.query.event_aid,
    };
    // editorOption里是放图片上传配置参数用的，例如：
    // action:  '/api/product/richtext_img_upload.do',  // 必填参数 图片上传地址
    // methods: 'post',  // 必填参数 图片上传方式
    // token: '',  // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
    // name: 'upload_file',  // 必填参数 文件的参数名
    // size: 500,  // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
    // accept: 'multipart/form-data, image/png, image/gif, image/jpeg, image/bmp, image/x-icon,image/jpg'  // 可选 可上传的图片格式
  },
  mounted() {
    if (this.$route.query.aid) {
      this.aid = this.$route.query.aid;
      this.getArticleDetail();
    }
    if (this.$route.query.ducoment_type) {
      console.log(555555);
      this.form.ducoment_type = this.$route.query.ducoment_type;
    }
    this.getArticleType();
  },
  methods: {
    addXiumiDialog(editorId) {
      window.UE.registerUI(
        "xiumi-dialog",
        (editor, uiName) => {
          // 创建 “秀米弹窗”
          const dialog = new window.UE.ui.Dialog({
            // 注意：这是 xiumi-ue-dialog-v5.html 文件的访问链接，这个页面会通过 iframe 的方式嵌入到弹窗里
            iframeUrl: "/UEditor/xiumi/xiumi-ue-dialog-v5.html",
            editor,
            name: uiName,
            title: "秀米图文消息助手",
            cssRules:
              "width: " +
              (window.innerWidth - 200) +
              "px; height: " +
              (window.innerHeight - 200) +
              "px;",
          });

          // 添加自定义按钮用于唤起“秀米弹窗”
          const btn = new window.UE.ui.Button({
            name: "xiumi-connect",
            title: "秀米",
            cssRules: `background-image: url('//dl.xiumi.us/connect/ue/xiumi-connect-icon.png') !important; background-size: contain;`,
            onclick() {
              dialog.render();
              dialog.open();
            },
          });

          return btn;
        },
        0 /* 指定添加到工具栏上的那个位置，默认时追加到最后 */,
        editorId /* 指定这个UI是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
      );
    },
    onChangeQuill(file, fileList) {
      console.log(file, fileList);
      // let fileName = file.uid + file.name
      // cosUtils.putObject(fileName, file.raw, 'operate/', (err, data) => { // 新闻图片存到operate/目录下
      //   console.log(err || data)
      //   if (!err) {
      //     let quill = this.$refs.myQuillEditor.quill
      //     let length = quill.getSelection().index
      //     // 图片上传到对象存储后的具体地址
      //     let imgSrc = `https://xiangc.oss-cn-beijing.aliyuncs.com/uploads/operate/${fileName}`
      //     quill.insertEmbed(length, "image", imgSrc)
      //     // 调整光标到最后
      //     quill.setSelection(length + 1)
      //   }
      // })
    },
    onSuccessQuill(res, file) {
      console.log(22, res, file);
      let quill = this.$refs.text.quill;
      let length = quill.getSelection().index;
      let imgSrc = res.data.data.file;
      console.log("图片链接", imgSrc);
      quill.insertEmbed(length, "image", imgSrc);
      // 调整光标到最后
      quill.setSelection(length + 1);
    },
    onEditorChange(event) {
      event.quill.deleteText(20000, 1);
      if (this.form.body === 0) {
        this.TiLength = 0;
      } else {
        this.TiLength = event.quill.getLength() - 1;
      }
    },

    // 获取文章分类
    getArticleType() {
      this.ajax("post", "/v1/61a96c1ee34fb", {}, (res) => {
        if (res.code == 1) {
          this.aTypeLists = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 上传成功回调
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    // 上传封面
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt10M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      // 	this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt10M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt10M;
    },
    submitForm() {
      console.log(123);
      let { title, body, is_original, is_first_publish, is_free, money, type } =
        this.form;
      if (is_original == 0 || is_first_publish == 0) {
        this.form.is_free = 1;
      }
      if (!title) {
        this.$message.error("请输入标题");
        return;
      }
      if (!body) {
        this.$message.error("请输入正文内容");
        return;
      }
      if (body.length < 300) {
        this.$message.error("正文内容最少300字哦");
        return;
      }
      if (body.length > 20000) {
        this.$message.error("正文内容不能多于两万字哦");
        return;
      }
      if (!type) {
        this.$message.error("请先选择文章分类");
        return;
      }
      if (is_original == 1 && is_first_publish == 1 && is_free == 0 && !money) {
        this.$message.error("请输入收费金额");
        return;
      }
      // 编辑
      if (this.aid) {
        this.form.article_aid = this.aid;
      }
      if (this.event_aid) {
        this.form.is_competition = 1;
      }
      this.ajax("post", "/v1/talents/publish_document", this.form, (res) => {
        if (res.code == 1) {
          this.joinActive(res.data.id);
        } else {
          this.$message.error(res.msg);
        }
      });
      // this.$router.push({
      // 	path: '/memberDate'
      // })
      // localStorage.setItem('issureData',JSON.stringify(this.form))
    },
    // 参与活动
    joinActive(document_id) {
      this.ajax(
        "post",
        "/v1/613b1acf77a26",
        {
          event_aid: this.event_aid,
          document_id: document_id,
        },
        (res) => {
          if (res.code == 1) {
            this.$message.success("报名成功");
            this.$router.go(-1); //返回上一层
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    // 获取文章详情
    getArticleDetail() {
      this.ajax(
        "post",
        "/v1/613afc809f5e3",
        {
          article_id: this.aid,
        },
        (res) => {
          if (res.code == 1) {
            this.form.title = res.data.title;
            this.form.body = res.data.body;
            this.form.is_original = res.data.is_original;
            this.form.is_first_publish = res.data.is_first_publish;
            this.form.is_free = res.data.is_free;
            this.form.type = res.data.type;
            if (res.data.is_free == 0) {
              this.form.money = Number(res.data.money);
            }
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.el-button {
  background: none;
  border: none;
  margin: 0 auto;
  margin-top: 5rem;
}
.edit-contain {
  width: 100vw;
  height: 100vh;
  background-color: #f8f8f8;
  overflow: auto;
}
/* /deep/.el-input--suffix .el-input__inner{
		padding-left: 0;
	} */
.contents {
  width: 80%;
  height: auto;
  margin: 80px auto;
}

.myQuillEditor {
  height: 400px;
}

/deep/.el-form-item__label {
  padding-right: 30px;
}

/deep/.el-radio__inner {
  width: 20px;
  height: 20px;
}

.el-form-item {
  display: flex;
}

/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #e54f42;
  background: #e54f42;
}

/deep/.el-radio__input.is-checked + .el-radio__label {
  color: #333333;
}
</style>
